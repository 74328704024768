<template>
	<UAvatar :size="props.size ? props.size : 'md'" :alt="props.name"
	         :chip-color="userOnline ? 'green' : 'gray'"
	         :ui="{chip:
	            {background: userOnline? 'animate-pulse bg-green-500 dark:bg-green-400' : 'bg-yellow-500 dark:bg-yellow-400'},
			 background: 'bg-green-200/80 dark:bg-green-800/80',
			 placeholder: 'font-medium leading-none text-green-600 dark:text-green-200 truncate',}"
				
	/>
</template>

<script setup lang="ts">
const props = defineProps<{ name: string, size?: "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "3xs" | "2xl" | "3xl" | undefined }>()
const userStore = useUserStore()

const onlineUsers = computed(() => userStore.onlineUsers)
const userOnline = computed(() => onlineUsers.value.find(u => u.name === props.name) !== undefined)
</script>